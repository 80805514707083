import { GENERIC } from "./actions/utilities";
import { baseURL } from "./config";
require('dotenv').config();
export default function setupAxios(axios, store) {
  // store.dispatch({
  //   type: GENERIC.SET_SESSION_EXPIRE_STATE,
  //   payload: true,
  // });
  axios.interceptors.request.use(
    (config) => {
      const storedState = localStorage.getItem("StateId");
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      config.headers.StateId = storedState;
      return config;
    },
    (err) => Promise.reject(err)
  );
  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      let user_refresh_token = window.localStorage.getItem("user_refresh_token")
      // If the response status is 401, it means the user is unauthorized.
      if (response.status === 401) {
        // store.dispatch({
        //   type: GENERIC.SET_SESSION_EXPIRE_STATE,
        //   payload: true,
        // }); 


        axios.post(`${baseURL}/account/RefreshToken`, {
          "grant_type": process.env.REACT_APP_GRANT_TYPE_REFRESH_TOKEN,
          "refresh_token": user_refresh_token
        })
          .then(response => {
            window.localStorage.setItem("user_refresh_token", response?.data?.refresh_token);

          })
          .catch(apiError => {
            console.error("API call error:", apiError);
          });


          axios.post(`${baseURL}/account/getToken`, {
            // "grant_type": process.env.REACT_APP_GRANT_TYPE_REFRESH_TOKEN,
            // "refresh_token": user_refresh_token
              "grant_type": "password",
              "username": "unicef_super_admin",
              "password": "Password@123"
          
          })
            .then(response => {
              window.localStorage.setItem("user_refresh_token", response?.data?.refresh_token);
            })
            .catch(apiError => {
              console.error("API call error:", apiError);
            });
      }
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        let user_refresh_token = window.localStorage.getItem("user_refresh_token")
        // store.dispatch({
        //   type: GENERIC.SET_SESSION_EXPIRE_STATE,
        //   payload: true,
        // });


        axios.post(`${baseURL}/account/RefreshToken`, {
          "grant_type": process.env.REACT_APP_GRANT_TYPE_REFRESH_TOKEN,
          "refresh_token": user_refresh_token
        })
          .then(response => {
            window.localStorage.setItem("user_refresh_token", response?.data?.refresh_token);
          })
          .catch(apiError => {
            console.error("API call error:", apiError);
          });


        axios.post(`${baseURL}/account/getToken`, {
          // "grant_type": process.env.REACT_APP_GRANT_TYPE_REFRESH_TOKEN,
          // "refresh_token": user_refresh_token
            "grant_type": "password",
            "username": "unicef_super_admin",
            "password": "Password@123"
        
        })
          .then(response => {
            window.localStorage.setItem("user_refresh_token", response?.data?.refresh_token);
          })
          .catch(apiError => {
            console.error("API call error:", apiError);
          });
      }
      if (error?.response?.status === 500) {
        return Promise.reject("");
      }
      return Promise.reject(error);
    }
  );
}
