export const reactSelectCustom = {
  control: (base) => ({
    ...base,
    background: "#13B9A0",
    borderRadius: "5px",
    border: "0",
    boxShadow: "none",
    boxSizing: "border - box",
    wordWrap: "break-word",
  }),
  menu: (base) => ({
    ...base,
    background: "#5FA9FF",
    color: "#fff",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected || state.isFocused ? "#13B9A0" : "",
    margin: "2px 0",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "white",
  }),
  singleValue: (base) => ({
    ...base,
    color: "#fff",
    fontWeigth: 500,
  }),
  valueContainer: (base) => ({
    ...base,
    color: "#fff",
    textOverflow: "ellipsis",
    maxWidth: "90%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    // display: "initial",
  }),
};

export const reactSelectStyleSm = {
  control: (base) => ({
    ...base,
    minHeight: "30px",
    height: "30px",
  }),
  valueContainer: (base) => ({
    ...base,
    height: "30px",
    padding: "0 6px",
  }),
  input: (base) => ({
    ...base,
    margin: "0px",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: "30px",
  }),
};

export const reactSelectCustomStyleSm = {
  control: (base) => ({
    ...base,
    background: "#13B9A0",
    borderRadius: "5px",
    border: "0",
    boxShadow: "none",
    boxSizing: "border - box",
    wordWrap: "break-word",
    minHeight: "30px",
    height: "30px",
  }),
  menu: (base) => ({
    ...base,
    background: "#5FA9FF",
    color: "#fff",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected || state.isFocused ? "#13B9A0" : "",
    margin: "2px 0",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "white",
  }),
  singleValue: (base) => ({
    ...base,
    color: "#fff",
    fontSize: 12,
    fontWeigth: "500",
  }),
  valueContainer: (base) => ({
    ...base,
    color: "#fff",
    textOverflow: "ellipsis",
    maxWidth: "90%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    // display: "initial",
    height: "30px",
    padding: "0 6px",
  }),
  input: (base) => ({
    ...base,
    margin: "0px",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: "30px",
  }),
  clearIndicator: (base) => ({
    ...base,
    color: "#fff",
  }),
  placeholder: (base) => ({
    ...base,
    color: "#fff",
  }),
};
