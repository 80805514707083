/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import { CgProfile } from "react-icons/cg";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import Select from "react-select";
import { PATH } from "../../../../../app/config";
import { dashboardOption } from "../../../../../app/constant";

import {
  FaClinicMedical,
  FaUserMd,
  FaVideo,
  FaListUl,
  FaHandHoldingHeart,
  FaBaby,
  FaHospitalUser,
  FaTachometerAlt,
  FaUserShield,
} from "react-icons/fa";
import { FcDataConfiguration } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountAction, getAllPoliciesAction } from "../../../../../redux/actions";
import { HeadsetMicOutlined } from "@material-ui/icons";
import { KEY_PREFIX } from "redux-persist";
import { FiTarget } from "react-icons/fi";

const DASHBOARDS = [
  // {
  //   name: "Dashboard",
  //   description: "",
  //   value: "sm:dashboard",
  //   subPolicies: null,
  //   path: PATH.MAIN_DASHBOARD,
  //   tags: null,
  //   icon: <FaTachometerAlt size="18" className="h-auto w-auto" />,
  // },
  {
    name: "Global Dashboard",
    description: "",
    value: "md:globalDashboard",
    subPolicies: null,
    path: PATH.MAIN_DASHBOARD,
    tags: null,
    icon: <FaTachometerAlt size="18" className="h-auto w-auto" />,
  },
  {
    name: "Immunization",
    description: "",
    value: "md:immunization",
    subPolicies: null,
    path: PATH.IMMUNIZATION_DASHBOARD,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    name: "Antenatal Care",
    description: "",
    value: "md:antenatalCare",
    subPolicies: null,
    path: PATH.ANC_DASHBOARD,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    name: "OPD Over Five",
    description: "",
    value: "md:overFive",
    subPolicies: null,
    path: PATH.OPD_FIVE_REGISTER_DASHBOARD,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    name: "OPD Under Five",
    description: "",
    value: "md:underFive",
    subPolicies: null,
    path: PATH.OPD_UNDER_FIVE_REGISTER_DASHBOARD,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    name: "Primary Health Unit",
    description: "",
    value: "md:primaryHealthUnit",
    subPolicies: null,
    path: PATH.PRIMARY_HEALTH_UNIT_DASHBOARD,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    name: "Hospital InPatient Register",
    description: "",
    value: "md:inPatientRegister",
    subPolicies: null,
    path: PATH.HOSPITAL_INPATIENT_REGISTER_DASHBOARD,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    name: "Posta Natal Care",
    description: "",
    value: "md:postaNatalCare",
    subPolicies: null,
    path: PATH.POSTNATAL_CARE_DASHBOARD,
    tags: null,
    icon: <FaListUl size="18" className="h-auto w-auto" />,
  },
  {
    name: "Labour Delivery Maternity",
    description: "",
    value: "md:labourDeliveryMaternity",
    subPolicies: null,
    path: PATH.LABOUR_DELIVERY_MATERNITY_DASHBOARD,
    tags: null,
    icon: <FaVideo size="18" className="h-auto w-auto" />,
  },
  {
    name: "Wash Register",
    description: "",
    value: "md:washRegister",
    subPolicies: null,
    path: PATH.WASH_REGISTER_DASHBOARD,
    tags: null,
    icon: <FaBaby size="18" className="h-auto w-auto" />,
  },
  {
    name: "Protection Monthly Reporting",
    description: "",
    value: "md:protectionMonthlyReporting",
    subPolicies: null,
    path: PATH.PROTECTION_MONTHLY_REPORTING_DASHBOARD,
    tags: null,
    icon: <FaHandHoldingHeart size="18" className="h-auto w-auto" />,
  },
  {
    name: "Emergency Preparedness Monthly Reporting",
    description: "",
    value: "md:emergencyPreparednessMonthlyReporting",
    subPolicies: null,
    path: PATH.EMERGENCY_PREPARENESS_MONTHLY_REPORTING_DASHBOARD,
    tags: null,
    icon: <FaHandHoldingHeart size="18" className="h-auto w-auto" />,
  },
  {
    name: "OTP",
    description: "",
    value: "md:otp",
    subPolicies: null,
    path: PATH.OTP_DASHBOARD,
    tags: null,
    icon: <FaHandHoldingHeart size="18" className="h-auto w-auto" />,
  },
  {
    name: "SC",
    description: "",
    value: "md:sc",
    subPolicies: null,
    path: PATH.SC_DASHBOARD,
    tags: null,
    icon: <FaHandHoldingHeart size="18" className="h-auto w-auto" />,
  },

];
export function AsideMenuList({ layoutProps }) {
  const [dashboard, setDashboard] = useState({});
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const counts = useSelector((state) => state.mainDashboard);
  let policesData = useSelector((state) => state?.clinicsReducer);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (!counts.allCountRecords.length > 0) {
      dispatch(getAllCountAction());
    }
    //eslint-disable-next-line
  }, []);
  const filteredPolicies = DASHBOARDS.filter((item) =>
    policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies?.some(
      (subItem) => subItem.value === item.value
    )
  );
  const ROLE_POLICES = [
    {
      id: 22,
      name: "Tally Validation",
      description: "",
      value: "sm:tally_validation",
      subPolicies: null,
      path: PATH.TALLY_VALIDATION,
      tags: null,
      icon: <FaTachometerAlt size="18" className="h-auto w-auto" />,
    },
    {
      id: 21,
      name: "Policy Group",
      description: "",
      value: "sm:roles_permissions",
      subPolicies: null,
      path: PATH.ROLES_AND_PERMISSIONS,
      tags: null,
      icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
    },
    {
      id: 20,
      name: "DHIS2 Facilities",
      description: "",
      value: "sm:dhis2_facilities",
      subPolicies: null,
      path: PATH.DHIS2_CONFIGURATION,
      tags: null,
      icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
    },
    {
      id: 19,
      name: "DHIS2 requests",
      description: "",
      value: "sm:dhis2_requests",
      subPolicies: null,
      path: PATH.DHIS_REQUESTS,
      tags: null,
      icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
    },
    {
      id: 17,
      name: "DHIS2 Configuration",
      description: "",
      value: "sm:dhis2_configuration",
      subPolicies: null,
      path: PATH.DHIS_CONFIGURATION,
      tags: null,
      icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
    },
    {
      id: 18,
      name: "DHIS2 Upload",
      description: "",
      value: "sm:dhis2_upload",
      subPolicies: null,
      path: PATH.DHIS_UPLOAD,
      tags: null,
      icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
    },
    {
      id: 16,
      name: "DHIS2 Logs",
      description: "",
      value: "sm:dhis2_logs",
      subPolicies: null,
      path: PATH.DHIS_LOGS,
      tags: null,
      icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
    },
    {
      id: 15,
      name: "Logging Activity",
      description: "",
      value: "sm:logging_activity",
      subPolicies: null,
      path: PATH.LOGGING_ACTIVITY,
      tags: null,
      icon: <FaListUl size="18" className="h-auto w-auto" />,
    },
    {
      id: 14,
      name: "Video Learning Centre",
      description: "",
      value: "sm:video_learning_centre",
      subPolicies: null,
      path: PATH.LEARNING_CENTRE,
      tags: null,
      icon: <FaVideo size="18" className="h-auto w-auto" />,
    },
    {
      id: 13,
      name: "Child List",
      description: "",
      value: "sm:child_list",
      subPolicies: null,
      path: PATH.CHILD_LIST,
      tags: null,
      icon: <FaBaby size="18" className="h-auto w-auto" />,
    },
    {
      id: 11,
      name: "Caregiver List",
      description: "",
      value: "sm:caregiver_list",
      subPolicies: null,
      path: PATH.CARE_GIVER_LIST,
      tags: null,
      icon: <FaHandHoldingHeart size="18" className="h-auto w-auto" />,
    },
    {
      id: 12,
      name: "Patients List",
      description: "",
      value: "sm:patients_list",
      subPolicies: null,
      path: PATH.PATIENT_LIST,
      tags: null,
      icon: <FaHospitalUser size="18" className="h-auto w-auto" />,
    },
    {
      id: 10,
      name: "Health Worker List",
      description: "",
      value: "sm:doctor_list",
      subPolicies: null,
      path: PATH.DOCTOR_LIST,
      tags: null,
      icon: <FaUserMd size="18" className="h-auto w-auto" />,
    },
    {
      id: 9,
      name: "Admin List",
      description: "",
      value: "sm:admin_list",
      subPolicies: null,
      path: PATH.ADMIN_LIST,
      tags: null,
      icon: <FaUserShield size="18" className="h-auto w-auto" />,
    },
    {
      id: 8,
      name: "Health Facility List",
      description: "",
      value: "sm:clinic_list",
      subPolicies: null,
      path: PATH.CLINIC_LIST,
      tags: null,
      icon: <FaClinicMedical size="18" className="h-auto w-auto" />,
    },

    {
      id: 111,
      name: "Target Listing",
      description: "",
      value: "sm:clinic_list",
      subPolicies: null,
      path: PATH.TARGET_LISTING,
      tags: null,
      icon: <FiTarget size="18" className="h-auto w-auto" />,
    },
    {
      id: 120,
      name: "Average Patient Case Profile",
      description: "",
      value: "sm:clinic_list",
      subPolicies: null,
      path: PATH.AVERAGE_PATIENT_CASE_PROFILE,
      tags: null,
      icon: <CgProfile size="18" className="h-auto w-auto" />,
    },
    {
      id: 7,
      name: "Dashboard",
      description: "",
      value: "sm:dashboard",
      subPolicies: null,
      path: filteredPolicies && filteredPolicies?.length > 0 && filteredPolicies?.[0]?.path,
      tags: null,
      icon: <FaTachometerAlt size="18" className="h-auto w-auto" />,
    },

  ];
  const getCount = (id) => {
    if (id == 9)
      return counts.allCountRecords.totalAdmins || 0
    else if (id == 10)
      return counts.allCountRecords.totalDoctors || 0
    else if (id == 11)
      return counts.allCountRecords.totalCaregivers || 0
    else if (id == 12)
      return counts.allCountRecords.totalPatients || 0
    else if (id == 8)
      return counts.allCountRecords.totalClinics || 0
    else if (id == 13)
      return counts.allCountRecords.totalChilds || 0
    else return -1
  }
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const handleOnChange = ({ value }) => {
    if (value) {
      if (value === 1) {
        return history.push(PATH.MAIN_DASHBOARD);
      }
      if (value === 2) {
        return history.push(PATH.IMMUNIZATION_DASHBOARD);
      }
      if (value === 3) {
        return history.push(PATH.ANC_DASHBOARD);
      }
      if (value === 4) {
        return history.push(PATH.OPD_FIVE_REGISTER_DASHBOARD);
      }
      if (value === 5) {
        return history.push(PATH.OPD_UNDER_FIVE_REGISTER_DASHBOARD);
      }
      if (value === 6) {
        return history.push(PATH.PRIMARY_HEALTH_UNIT_DASHBOARD);
      }
      if (value === 7) {
        return history.push(PATH.HOSPITAL_INPATIENT_REGISTER_DASHBOARD);
      }
    }
  };



  const handleDashboard = (current) => {

    let option = [...dashboardOption];

    if (current === PATH.IMMUNIZATION_DASHBOARD) {
      return setDashboard(option[1]);
    }
    if (current === PATH.ANC_DASHBOARD) {
      return setDashboard(option[2]);
    }

    return setDashboard(option[0]);
  };

  useEffect(() => {
    handleDashboard(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // let combinedData=ROLE_POLICES.filter((item)=>policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies?.some(
  //   (subItem) => subItem.value === item.value
  // ))
  const combinedData = ROLE_POLICES.filter((item) =>
    policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies?.some(
      (subItem) => subItem.value === item.value
    )
  );
  const SidebarItems = () => {
    return (
      <>
        {combinedData?.reverse()?.map((item) => {
          return (
            <>
              <li
                className={`p-2 menu-item ${getMenuItemActive(
                  item.path,
                  false
                )} ${getMenuItemActive(item.path, false)} ${getMenuItemActive(
                  item.path,
                  false
                )}`}
                aria-haspopup="true"
                style={{ borderRadius: "6px !important" }}
              >
                <NavLink className="menu-link" to={item.path}>
                  <span className="svg-icon menu-icon text-dark">
                    {item.icon ? item.icon : "N/A"}
                  </span>
                  <span className="menu-text text-dark">{item.name}</span>
                  {
                    getCount(item.id) > 0 && (<span className="menu-arrow text-dark">
                      {getCount(item.id)}
                    </span>
                    )
                  }
                </NavLink>
              </li>
            </>
          );
        })}
      </>
    );
  };

  return (
    <>
      <ul className={`menu-nav bg-white ${layoutProps.ulClasses}`}
        onClick={() => dispatch({ type: "RESET_LOCATIONS" })}>
        <SidebarItems />
      </ul>
    </>
  );


}
